import { extend } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { useLoader } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier";
import { MeshPhysicalMaterial } from "three";

extend({ TextGeometry });

export default function Text() {
  // Load the font
  const font = useLoader(FontLoader, "/fonts/open_sans.json");
  const text = "adidharmatoru";
  const letters = text.split('');

  return (
    <>
      {letters.map((letter, index) => (
        <RigidBody key={index} position={[-15, -1, 5 - index * 0.85]} rotation={[0, 1.46, 0]}>
          <mesh>
            <textGeometry attach="geometry" args={[letter, { font: font, size: 1, height: 0.6 }]} />
            <meshPhysicalMaterial attach='material' color={'lightsteelblue'} />
          </mesh>
        </RigidBody>
      ))}
    </>
  );
}
