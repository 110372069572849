import React, { useState, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { EcctrlJoystick } from 'ecctrl'

const EcctrlJoystickControls = () => {
    const [isTouchScreen, setIsTouchScreen] = useState(false);

    useEffect(() => {
        const checkTouchScreen = () => {
            if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) {
                setIsTouchScreen(true);
            } else {
                setIsTouchScreen(false);
            }
        };

        checkTouchScreen();

        window.addEventListener('touchstart', checkTouchScreen);

        return () => {
            window.removeEventListener('touchstart', checkTouchScreen);
        };
    }, []);

    const blackMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: "#A9A9A9" }), []);
    const greyMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: "#BEBEBE" }), []);
    const whiteMaterial = useMemo(() => new THREE.MeshStandardMaterial({ color: "#FFFFFF" }), []);

    return (
        <>
            {isTouchScreen && (
                <EcctrlJoystick
                    buttonNumber={1}
                    joystickBaseProps={{ receiveShadow: true, material: blackMaterial }}
                    joystickStickProps={{ receiveShadow: true, material: greyMaterial }}
                    joystickHandleProps={{ receiveShadow: true, material: whiteMaterial }}
                    buttonLargeBaseProps={{ receiveShadow: true, material: blackMaterial }}
                    buttonTop1Props={{ receiveShadow: true, material: whiteMaterial }}
                >
                    <ambientLight />
                </EcctrlJoystick>
            )}
        </>
    );
};

export default EcctrlJoystickControls;
