import { RigidBody } from "@react-three/rapier";
import { useGLTF } from "@react-three/drei";

export default function Tiles() {
  // Load model
  const { nodes: baseTileNodes } = useGLTF("./models/environment/tiles/base.glb");
  const { nodes: brokenTileNodes } = useGLTF("./models/environment/tiles/broken.glb");
  const { nodes: chippedTileNodes } = useGLTF("./models/environment/tiles/chipped.glb");
  const { nodes: shatteredTileNodes } = useGLTF("./models/environment/tiles/shattered.glb");

  // Define positions
  const pos = [{
    node: baseTileNodes,
    position: [-5, -1, -5], 
    rotation: [4.72, 0, 0],
  },
  {
    node: brokenTileNodes,
    position: [-5, -1, -6], 
    rotation: [4.72, 0, 0],
  },
  {
    node: chippedTileNodes,
    position: [-5, -1, -7], 
    rotation: [4.72, 0, 0],
  },
  {
    node: shatteredTileNodes,
    position: [-5, -1, -8], 
    rotation: [4.72, 0, 0],
  }];

  return (
    <>
      {pos.map(({ node, position, rotation }, index) => (
        <RigidBody
          key={index}
          position={position}
          rotation={rotation}
          linearDamping={0.5}
          angularDamping={0.5}
          type="fixed"
        >
          {/* Render meshes inside RigidBody */}
          {Object.values(node).map((node, index) => (
            <mesh
              key={index}
              geometry={node.geometry}
              material={node.material}>
              <meshStandardMaterial color={"grey"} />
            </mesh>
          ))}
        </RigidBody>
      ))}
    </>
  );
}
