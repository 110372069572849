import { RigidBody } from "@react-three/rapier";

export default function Floor() {
  return (
    <RigidBody type="fixed">
      <mesh receiveShadow position={[0, -3.5, 0]}>
        <boxGeometry args={[300, 5, 300]} />
        {/* <meshStandardMaterial color="#FFFFFF" /> */}
        <shadowMaterial opacity={0.4} />
      </mesh>
    </RigidBody>
  );
}
