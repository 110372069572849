import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Map from './Map';
import { Leva } from 'leva';
import EcctrlJoystickControls from './props/EcctrlJoystickControls';
import LoadingScreen from './props/LoadingScreen';

export default function World() {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(true); // Track if the map should be loaded
    const [mapLoaded, setMapLoaded] = useState(false); // Track if the map is loaded

    const handleContinue = (enteredName) => {
        setName(enteredName);
        setLoading(false); // Set to false when continue button is clicked to start loading the map
    };

    useEffect(() => {
        // Simulate map loading process
        const loadMap = async () => {
            // Simulate some asynchronous loading process
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the duration as needed

            // Map loading is complete
            setMapLoaded(true);
        };

        loadMap(); // Load the map when component mounts
    }, []);

    useEffect(() => {
        if (!loading) {
            const canvas = document.querySelector('canvas');
            const pointerLockHandler = (e) => {
                if (e.pointerType === 'mouse') {
                    e.target.requestPointerLock();
                }
            };
            if (canvas) {
                canvas.addEventListener('pointerdown', pointerLockHandler);
            }
            return () => {
                if (canvas) {
                    canvas.removeEventListener('pointerdown', pointerLockHandler);
                }
            };
        }
    }, [loading]);

    return (
        <>
            <Leva />
            <EcctrlJoystickControls />
            <CanvasWrapper
                name={name}
                loading={loading}
                setLoading={setLoading}
                mapLoaded={mapLoaded}
                handleContinue={handleContinue}
            />
        </>
    );
}

function CanvasWrapper({ name, loading, setLoading, mapLoaded, handleContinue }) {
    return (
        <Canvas
            shadows
            camera={{
                fov: 65,
                near: 0.1,
                far: 100,
            }}
            onPointerDown={(e) => {
                if (!loading && e.pointerType === 'mouse') {
                    e.target.requestPointerLock();
                }
            }}
        >
            {loading ? (
                <LoadingScreen onContinue={handleContinue} />
            ) : mapLoaded ? (
                <Map name={name} />
            ) : null}
        </Canvas>
    );
}
