import React, { useState } from 'react';
import { Html } from '@react-three/drei';

const LoadingScreen = ({ onContinue }) => {
    const [name, setName] = useState(''); // Track name in local state

    const handleInputChange = (event) => {
        setName(event.target.value);
    };

    const handleContinueClick = () => {
        // Call the onContinue function with the entered name
        onContinue(name);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleContinueClick();
        }
    };

    return (
        <Html className='html'>
            <div className='logo'>
                <img src="/logo192.png" alt="Logo" loading="lazy" />
            </div>
            <div className="name-input mt-25">
                <input type="text" placeholder="Name your character" value={name} onChange={handleInputChange} onKeyPress={handleKeyPress} />
            </div>
            <div className="continue-button mt-25">
                <button onClick={handleContinueClick}>Play</button>
            </div>
        </Html>
    );
};

export default LoadingScreen;
