import { RigidBody } from "@react-three/rapier";
import { useGLTF } from "@react-three/drei";

export default function Bricks() {
  // Load model
  const { nodes: brickNodes } = useGLTF("./models/environment/brick.glb");

  // Define positions
  const pos = [];
  for (let i = 0; i < 3; i++) {
    pos.push({
      position: [-5 - i, -0.7, -10],
      rotation: [0, 0, 1.57],
    });
  }
  
  for (let i = 0; i < 3; i++) {
    pos.push({
      position: [-5 - i, -0.7, 10],
      rotation: [0, 0, 1.57],
    });
  }

  return (
    <>
      {pos.map(({ position, rotation }, index) => (
        <RigidBody
          key={index}
          position={position}
          rotation={rotation}
          linearDamping={0.5}
          angularDamping={0.5}
        >
          {/* Render meshes inside RigidBody */}
          {Object.values(brickNodes).map((node, index) => (
            <mesh receiveShadow castShadow
              key={index}
              geometry={node.geometry}
              material={node.material}>
              <meshStandardMaterial color={"lightsteelblue"} />
            </mesh>
          ))}
        </RigidBody>
      ))}
    </>
  );
}
