import React, { useEffect, useState } from 'react';
import { Text, useGLTF } from '@react-three/drei'

export default function CharacterModel({ name, ...props }) {
  const { nodes, materials } = useGLTF('/models/char/ghost_w_tophat.glb')

  return (
    <group {...props} dispose={null}>
      <group scale={0.0034} position={[0, -0.55, 0]}>
        <group position={[0, 155.777, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <Text
          scale={0.5}
          rotation={[Math.PI / 2, 0, 0]}
          color="black"
          maxWidth={10}
          textAlign="center"
          position={[0, 0, 1.5]}
        >
          {name}
        </Text>
          <mesh castShadow receiveShadow geometry={nodes.Eyes_Eyes_0.geometry} material={materials.Eyes} />
          <mesh castShadow receiveShadow geometry={nodes.Eyes_Ghost_White_0.geometry} material={materials.Ghost_White} />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body_Ghost_White_0.geometry}
          material={materials.Ghost_White}
          position={[0, 155.777, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/models/char/ghost_w_tophat.glb')
