import { Grid, KeyboardControls } from "@react-three/drei";
import { Perf } from "r3f-perf";
import { Physics } from "@react-three/rapier";
import Ecctrl from 'ecctrl'
import Floor from "./geometries/Floor";
import Lighting from "./experiences/Lighting";
import Steps from "./geometries/Steps";
import Slopes from "./geometries/Slopes";
import RoughPlane from "./geometries/RoughPlane";
import RigidObjects from "./geometries/RigidObjects";
import Bricks from "./geometries/Bricks";
import Tiles from "./geometries/Tiles";
import Text from "./geometries/Text";
import FloatingPlatform from "./geometries/FloatingPlatform";
import DynamicPlatforms from "./geometries/DynamicPlatforms";
import ShotCube from "./props/ShotCube";
import { useControls } from "leva";
import CharacterModel from "./CharacterModel";
import React from "react";
import { Trees } from "./geometries/Trees";
import { Color } from "three";
import { useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";

export default function Map({ name }) {
  /**
   * Delay physics activate
   */
  // const [pausedPhysics, setPausedPhysics] = useState(true);
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setPausedPhysics(false);
  //   }, 1000);

  //   return () => clearTimeout(timeout);
  // }, []);

  /**
   * Debug settings
   */
  // const { physics, disableFollowCam } = useControls("World Settings", {
  //   physics: false,
  //   disableFollowCam: false,
  // });

  const refTrees = useRef(null);

  useFrame(() => {
    const { current: group } = refTrees;
    if (group) {
      group.rotation.x = group.rotation.y += 0.01;
    }
  });

  /**
   * Keyboard control preset
   */
  const keyboardMap = [
    { name: "forward", keys: ["ArrowUp", "KeyW"] },
    { name: "backward", keys: ["ArrowDown", "KeyS"] },
    { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
    { name: "rightward", keys: ["ArrowRight", "KeyD"] },
    { name: "jump", keys: ["Space"] },
    { name: "run", keys: ["Shift"] },
  ];

  return (
    <>
      {/* <Perf position="top-left" minimal /> */}

      {/* <Grid
        args={[300, 300]}
        sectionColor={"white"}
        cellColor={"white"}
        position={[0, -0.99, 0]}
        userData={{ camExcludeCollision: true }} // this won't be collided with by camera ray
      /> */}

      <Lighting />

      <Physics 
        // debug={physics} 
        timeStep="vary" 
        // paused={pausedPhysics}
      >
        {/* Keyboard preset */}
        <KeyboardControls map={keyboardMap}>
          {/* Character Control */}
          <Ecctrl
            // debug
            animated
            followLight
            springK={2}
            dampingC={0.2}
            autoBalanceSpringK={1.2}
            autoBalanceDampingC={0.04}
            autoBalanceSpringOnY={0.7}
            // autoBalanceDampingOnY={0.1}
            // disableFollowCam={disableFollowCam}
            position={[0, 10, 0]}
            camInitDir={{ x: 0, y: 4.72 }}
            characterInitDir={4.72}
          >
            {/* Replace your model here */}
            {/* <EcctrlAnimation characterURL={characterURL} animationSet={animationSet}> */}
              <CharacterModel name={name} />
            {/* </EcctrlAnimation> */}
          </Ecctrl>
        </KeyboardControls>

        {/* Rough plane */}
        <RoughPlane />

        {/* Slopes and stairs */}
        <Slopes />

        {/* Small steps */}
        <Steps />

        {/* Rigid body objects */}
        <RigidObjects />

        {/* Brick objects */}
        <Bricks />

        {/* Tile objects */}
        <Tiles />

        {/* Text objects */}
        <Text />

        {/* Floating platform */}
        <FloatingPlatform />

        {/* Dynamic platforms */}
        <DynamicPlatforms />

        {/* Floor */}
        <Floor />

        {/* Shooting cubes */}
        <ShotCube />

        <Trees
        ref={refTrees}
        position={[-12, 9, 10]}
        colors={[
          new Color("#427062").convertLinearToSRGB(),
          new Color("#33594e").convertLinearToSRGB(),
          new Color("#234549").convertLinearToSRGB(),
          new Color("#1e363f").convertLinearToSRGB(),
        ]}
      />
      </Physics >
    </>
  );
}
